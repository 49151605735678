var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  exports = function () {
    throw new Error("Readable.from is not available in the browser");
  };

  return exports;
}